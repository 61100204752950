<template>
	<MobileAppWizardStep
		v-model="application"
		:steps="steps"
		:step-name="stepName"
		:start-with-last="startWithLast"
		@previous="$emit('previous')"
		@next="$emit('next')"
		@unvalidated="$emit('unvalidated')"
		@validated="$emit('validated')"
	/>
</template>

<script>
export default {
	name: 'MobileAppStepFinalize',
	components: {
		MobileAppWizardStep: () => ({
			component: import('@/components/SuperAdmin/MobileApp/CreationSteps/MobileAppWizardStep')
		})
	},
	props: {
		value: {
			required: true,
			type: Object
		},
		startWithLast: {
			required: false,
			type: Boolean,
			default: false
		},
		stepName: {
			required: true,
			type: String
		}
	},
	data: function () {
		return {
			stepsValidated: false,
			steps: [
				{
					title: this.$t('mobileapp.creationSteps.finalize.steps.1.title')
				}
			]
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
